/* eslint-disable no-underscore-dangle,no-var,vars-on-top */
export function Init() {
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ id: '3328424', type: 'pageView', start: new Date().getTime() });
  // eslint-disable-next-line func-names
  (function(d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement('script');
    ts.type = 'text/javascript';
    ts.async = true;
    ts.defer = false;
    ts.id = id;
    ts.src = 'https://top-fwz1.mail.ru/js/code.js';
    // eslint-disable-next-line func-names
    var f = function() {
      var s = d.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ts, s);
    };
    if (w.opera === '[object Opera]') {
      d.addEventListener('DOMContentLoaded', f, false);
    } else {
      f();
    }
  })(document, window, 'topmailru-code');
}
