import { loadImage } from 'src/utils/load-image';
import { loadScript } from 'src/utils/load-script';

/* eslint-disable no-undef,func-names */
/**
 * skins/default/en/modules/Itech/CheckoutV2/checkout/review.tpl
 * dataSource : classes/XLite/Module/Itech/MainPage/View/MainPageProducts.php::getViewHomeVKCode
 * @param data
 */
export function ProductPage(data) {
  const value = data.vk;
  if (window.VK === undefined) {
    return;
  }
  VK.Retargeting.ProductEvent(value.price_num, value.event, {
    products: value.products,
    business_value: value.business_value,
    category_ids: value.category_ids,
    currency_code: value.currency_code,
    total_price: value.total_price,
  });
}

/**
 * Catalog
 * skins/default/en/modules/Itech/Ochkarik/skin/en/product/details/parts/page.info.tpl
 * dataSource : classes/XLite/Module/Itech/Ochkarik/View/Product/Details/Customer/Page/Main.php::getViewProductVKCode
 * @param data
 */
export function ProductDetail(data) {
  const value = data;
  if (window.VK === undefined) {
    return;
  }
  VK.Retargeting.ProductEvent(value.price_num, value.event, {
    products: value.products,
    business_value: value.business_value,
    category_ids: value.category_ids,
    currency_code: value.currency_code,
    total_price: value.total_price,
  });
}

/**
 * skins/default/en/modules/Itech/CheckoutV2/checkout/billing.tpl
 * dataSource : classes/XLite/Module/Itech/Ochkarik/View/AView.php::getCheckoutVKCode
 * @deprecated
 */

export function BeginCheckout({ items }) {
  if (window.VK === undefined) {
    return;
  }
  const price = items.reduce((acc, curr) => acc + Number(curr.total), 0);
  VK.Retargeting.ProductEvent(price, 'init_checkout', {
    products: items.map(item => {
      return {
        id: item.id,
        recommended_ids: '18020,7418',
        price: item.price,
      };
    }),
    business_value: '1200',
    category_ids: '19',
    currency_code: 'RUR',
    total_price: price,
  });
}

/**
 * skins/default/en/modules/Itech/CheckoutV2/checkout/review.tpl
 * dataSource : classes/XLite/Module/Itech/Ochkarik/View/AView.php::getCheckoutVKCode
 * @deprecated
 * @param data
 */
// export function ShippingCheckout(data) {
//   if (window.VK === undefined) {
//     return;
//   }
//   VK.Retargeting.ProductEvent(data.product.price_num, 'add_payment_info', {
//     products: [
//       {
//         id: '$productId',
//         recommended_ids: '$productIds',
//         price: '$productPrice',
//       },
//     ],
//     business_value: '1200',
//     category_ids: '$categoryIds',
//     currency_code: 'RUR',
//     total_price: '$totalPrice',
//   });
// }

/**
 * skins/default/en/modules/Itech/MedicalRecord/js/common.js
 */
export function MedicalAppointment() {
  if (window.VK === undefined) {
    return;
  }
  VK.Retargeting.Event('Medical_appointment');
}

/**
 * skins/default/en/modules/Itech/Ochkarik/js/index.js
 * skins/default/en/modules/Itech/UniversalAnalytics/js/add_to_cart.js
 */
export function AddToCart(data) {
  const { product } = data;
  if (!product.price_num) {
    return false;
  }
  if (window.VK === undefined) {
    return false;
  }
  VK.Retargeting.Event('AddToCart');
  VK.Retargeting.ProductEvent(product.price_num, 'add_to_cart', {
    products: [
      {
        id: product.productVariant,
        price: parseInt(product.salePrice, 10),
        // @see https://vk.com/faq12164
        price_old: parseInt(product.price, 10),
      },
    ],
    // @todo
    // total_price: parseInt(product.total, 10),
    business_value: 800,
    category_ids: product.category_id,
    currency_code: 'RUR',
  });
}

/**
 * skins/default/en/modules/Itech/Ochkarik/js/index.js
 * skins/default/en/modules/Itech/UniversalAnalytics/js/add_to_cart.js
 */
export function RemoveFromCart(data) {
  if (window.VK === undefined) {
    return;
  }
  const { product } = data;
  if (!product.price_num) {
    return;
  }

  VK.Retargeting.ProductEvent(product.price_num, 'remove_from_cart', {
    products: [
      {
        id: product.productVariant,
        price: product.price,
      },
    ],
    business_value: 800,
    category_ids: product.category_id,
    currency_code: 'RUR',
  });
}

/**
 * skins/default/en/modules/Itech/Ochkarik/js/index.js
 * not used anywhare
 */
export function MedicalAppointmentSale() {
  if (window.VK === undefined) {
    return;
  }
  VK.Retargeting.Event('Medical_appointment_sale');
}

export function Init() {
  const { protocol } = window.location;

  const executor = () => {
    const images = [`${protocol}//vk.com/rtrg?p=VK-RTRG-861794-cmDVo`];

    const keys = ['VK-RTRG-861794-cmDVo'];

    images.forEach(image => {
      loadImage(image);
    });

    keys.forEach(k => {
      VK.Retargeting.Init(k);
      VK.Retargeting.Hit();
    });
  };

  if (window.VK) {
    return executor();
  }

  loadScript('https://vk.com/js/api/openapi.js?154').then(executor);
}
