/* eslint-disable no-empty,no-undef,no-param-reassign,prefer-destructuring,prettier/prettier,func-names */
export function Init() {
  (function(m, e, t, r, i, k, a) {
    m[i] = m[i] || function () {
      // eslint-disable-next-line prefer-rest-params
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * new Date();
    k = e.createElement(t);
    a = e.getElementsByTagName(t)[0];
    k.async = 1;
    k.defer = 0;
    k.src = r;
    a.parentNode.insertBefore(k, a);
  })
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(23619250, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: 'dataLayer',
  });
}

export function BindCheckoutButton() {
  const buttonEl = document.querySelector('div.basket__checkout-button button');
  if (buttonEl === null) {
    return;
  }
  buttonEl.addEventListener('click', () => {
    try {
      yaCounter23619250.reachGoal('checkout-cart');
    } catch (err) {}
  });
}


export function GoalReached(goal){
  if(window.yaCounter23619250 === undefined){
    return;
  }
  yaCounter23619250.reachGoal(goal);
}
