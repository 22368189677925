import Analytics from 'src/features/analytics';
import { ANALYTICS } from 'src/constants/analytics';

import {
  GtagAuth,
  GtagCartItem,
  GtagCheckoutProgress,
  GtagEventSend,
  GtagListingView,
  GtagRegister,
  GtagWithPayload,
  MedicalAppointment,
  MedicalAppointmentSale,
  ProductShow,
  GtagProductClick,
  ShowBanners,
  ClickBanners,
  Init as _Init,
  GtagBeginCheckout,
} from './google';

import {
  GtagViewItemListGa4,
  GtagProductClickGa4,
  GtagProductDetailGa4,
  GtagCartItemGa4,
  GtagBeginCheckoutGa4,
  GtagPurchaseGa4,
  GtagViewPromotionGa4,
  GtagSelectPromotionGa4
} from './ga4.js';

export function Init() {
  _Init();

  /* impressions UA */
  Analytics.addListener([ANALYTICS.SHOW_PRODUCT], ProductShow);
  /* impressions GA4 */
  Analytics.addListener([ANALYTICS.SHOW_PRODUCT], GtagViewItemListGa4);

  /* productClick UA */
  Analytics.addListener([ANALYTICS.PRODUCT_CLICK], GtagProductClick);
  /* select_item GA4 */
  Analytics.addListener([ANALYTICS.PRODUCT_CLICK], GtagProductClickGa4);

  /* view_item GA4 */
  Analytics.addListener([ANALYTICS.PRODUCT_DETAIL], GtagProductDetailGa4);

  /* add_to_cart GA4*/
  Analytics.addListener([ANALYTICS.ITEM_ADD], data => {
    data.isAdd = true;
    GtagCartItemGa4(data)
  });

  /* removeFromCart UA */
  Analytics.addListener([ANALYTICS.ITEM_REMOVE], data => {
    data.isAdd = false;
    GtagCartItem(data)
  });
  /* remove_from_cart GA4 */
  Analytics.addListener([ANALYTICS.ITEM_REMOVE], GtagCartItemGa4);

  /* checkout step 1 UA */
  Analytics.addListener([ANALYTICS.BEGIN_CHECKOUT], data => {
    data.step = 1;
    GtagBeginCheckout(data)
  });
  /* begin_checkout GA4 */
  Analytics.addListener([ANALYTICS.BEGIN_CHECKOUT], GtagBeginCheckoutGa4);

  /* checkout step 2 UA */
  Analytics.addListener([ANALYTICS.PHONE_ENTRY_CHECKOUT], data => {
    data.step = 2;
    GtagBeginCheckout(data);
  });

  /* checkout step 3 UA */
  Analytics.addListener([ANALYTICS.CODE_ENTRY_CHECKOUT], data => {
    data.step = 3;
    GtagBeginCheckout(data);
  });

  /* purchase GA4 */
  Analytics.addListener([ANALYTICS.SUCCESS_CHECKOUT], data => {
    GtagPurchaseGa4(data)
  });


  // TODO все переделать наподобие данного события
  Analytics.addListener([ANALYTICS.LISTING_VIEW], GtagListingView);
  Analytics.addListener([ANALYTICS.AUTH], GtagAuth);
  Analytics.addListener([ANALYTICS.REGISTER], GtagRegister);
  Analytics.addListener([ANALYTICS.PROGRESS], GtagCheckoutProgress);
  Analytics.addListener([ANALYTICS.GTAG_EVENT], GtagEventSend);
  Analytics.addListener([ANALYTICS.APP_STORE], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'check-in', event_action: 'open' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.GOOGLE_PLAY], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'check-in-google', event_action: 'open' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.FORM_SALON], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'doctor-entry', event_action: 'form-salon' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.FORM_SPECIALIST], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'doctor-entry', event_action: 'form-specialist' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.FORM_DATE], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'doctor-entry', event_action: 'form-date' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.GALERY_CLICK], ClickBanners);
  /* select_promotion GA4 */
  Analytics.addListener([ANALYTICS.GALERY_CLICK], GtagSelectPromotionGa4);

  /* promoView UA */
  Analytics.addListener([ANALYTICS.BANNER], ShowBanners);
  /* view_promotion GA4 */ 
  Analytics.addListener([ANALYTICS.BANNER], GtagViewPromotionGa4);

  Analytics.addListener([ANALYTICS.CATALOG], data => {
    if (data === undefined || data.catalog === undefined) {
      return;
    }
    const payload = {
      event: 'event',
      event_name: 'view_item_list',
      info: { items: data.catalog },
    };
    GtagWithPayload(payload);
  });

  let el = document.querySelector('.analyticsSendGoogleEventPerehodFb');
  if (el !== null) {
    el.addEventListener('click', () => {
      const payload = {
        event: 'event',
        event_name: 'event_name',
        info: { event_category: 'perehod', action: 'FB' },
      };
      GtagWithPayload(payload);
    });
  }

  el = document.querySelector('.analyticsSendGoogleEventPerehodVK');
  if (el !== null) {
    el.addEventListener('click', () => {
      const payload = {
        event: 'event',
        event_name: 'event_name',
        info: { event_category: 'perehod', action: 'VK' },
      };
      GtagWithPayload(payload);
    });
  }

  el = document.querySelector('.analyticsSendGoogleEventPerehodIM');
  if (el !== null) {
    el.addEventListener('click', () => {
      const payload = {
        event: 'event',
        event_name: 'event_name',
        info: { event_category: 'perehod', action: 'IM' },
      };
      GtagWithPayload(payload);
    });
  }

  Analytics.addListener([ANALYTICS.BANNER_CLICK], data => {
    if (data === undefined) {
      return;
    }
    const payload = {
      event: 'event',
      event_name: 'select_content',
      info: { promotions: [{ name: data }] },
    };

    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.BILLING_CHECKOUT], data => {
    try {
      const url = window.URLHandler.buildURL({
        target: 'AnalyticsCustomer',
        action: 'getOrderData',
      });
      $.get(url, { order: data, step: 'billing' }, res => {
        if (!res || !JSON.parse(res).cart) {
          return;
        }
        const payload = {
          event: 'event',
          event_name: 'checkout_progress',
          info: { items: JSON.parse(res).cart },
        };
        GtagWithPayload(payload);

        payload.event_name = 'set_checkout_option';
        payload.info = {
          checkout_step: 2,
          checkout_option: 'vhod',
          value: JSON.parse(res).add,
        };
        GtagWithPayload(payload);
      });
    } catch (e) { console.warn(e) }
  });

  Analytics.addListener([ANALYTICS.SHIPPING_CHECKOUT], data => {
    try {
      const url = window.URLHandler.buildURL({
        target: 'AnalyticsCustomer',
        action: 'getOrderData',
      });
      $.get(url, { order: data, step: 'shipping' }, res => {
        if (!res || !JSON.parse(res).cart) {
          return;
        }
        const payload = {
          event: 'event',
          event_name: 'checkout_progress',
          info: { items: JSON.parse(res).cart },
        };
        GtagWithPayload(payload);

        payload.event_name = 'set_checkout_option';
        payload.info = {
          checkout_step: 3,
          checkout_option: 'dostavka',
          value: JSON.parse(res).add,
        };
        GtagWithPayload(payload);
      });
    } catch (e) { console.warn(e) }
  });

  Analytics.addListener([ANALYTICS.SUCCESS_CHECKOUT], data => {
    if (data.gtag && data.gtag.isEventSent) {
      return;
    }
    try {
      if (data.gtag) {
        gtag('event', 'conversion', {
          send_to: 'AW-808555347/zrjoCPiaubUBENOmxoED',
          value: data.gtag.orderTotal,
          currency: data.gtag.currency,
          transaction_id: '',
          event_callback: function () {},
        });
      }

      const url = window.URLHandler.buildURL({
        target: 'AnalyticsCustomer',
        action: 'getOrderData',
      });
      $.get(url, { order: data.orderId, step: 'success' }, res => {
        if (!res || !JSON.parse(res).cart || !JSON.parse(res).add) {
          return;
        }
        const add = JSON.parse(res).add;
        const cart = JSON.parse(res).cart;
        const payload = {
          event: 'event',
          event_name: 'checkout_progress',
          info: { items: cart },
        };
        GtagWithPayload(payload);

        payload.event_name = 'set_checkout_option';
        payload.info = {
          checkout_step: 4,
          checkout_option: 'oplata',
          value: add.payment,
        };
        GtagWithPayload(payload);

        payload.event_name = 'set_checkout_option';
        payload.info = {
          checkout_step: 4,
          checkout_option: 'podtverzhdenie',
          value: add.confirm,
        };
        GtagWithPayload(payload);
      });
    } catch (e) { console.warn(e) }
  });

  Analytics.addListener([ANALYTICS.SEND_GOOGLE_EVENT], data => {
    if (data === undefined) {
      return;
    }
    const payload = { event: 'event', event_name: 'event_name', info: {} };

    payload.info = data;
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.MEDICAL_APPOINTMENT_SALE], () => {
    MedicalAppointmentSale();
  });

  Analytics.addListener([ANALYTICS.MEDICAL_APPOINTMENT], (record) => {
    MedicalAppointment(record);
  });

  Analytics.addListener([ANALYTICS.NO_LK_CHECKOUT], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'number_nonlk', event_action: 'send' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.CALLBACK_FORM], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'callback-form', event_action: 'send' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.REVIEW_SEND], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'review', event_action: 'send' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.COMPARE], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'compare', event_action: 'compare' },
    };
    GtagWithPayload(payload);
  });

  Analytics.addListener([ANALYTICS.DOCTOR_ENTRY_SEND], () => {
    const payload = {
      event: 'event',
      event_name: 'event_name',
      info: { event_category: 'doctor-entry', event_action: 'form-send' },
    };
    GtagWithPayload(payload);
  });
}
