export const loadImage = url => {
  return new Promise((resolve, reject) => {
    const img = window.Image ? new Image() : document.createElement('img');

    img.onload = resolve;
    img.onerror = reject;

    img.src = url;
  });
};
