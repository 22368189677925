import { ANALYTICS } from 'src/constants';
import Analytics from 'src/features/analytics/index';

const InitAllTriggers = () => {
  Object.keys(ANALYTICS).forEach(analyticTriggers => {
    window.xcore.bind(ANALYTICS[analyticTriggers], (event, data) => {
      Analytics.trigger(ANALYTICS[analyticTriggers], data);
    });
  });
};

export { InitAllTriggers };
