function Init() {
  // eslint-disable-next-line
  (function(d,w,t,u,o,s,v){w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};s=d.createElement('script');s.async=1;s.src=u;v=d.getElementsByTagName(t)[0];v.parentNode.insertBefore(s,v)})(document,window,'script','//image.sendsay.ru/js/target/tracking_v2.min.js','sndsy');sndsy('init',{fid:'ochkarik',v:'1.0'});sndsy('send',{});
}

function pushEvent(eventName, payload) {
  try {
    (window.sndsyApiOnReady = window.sndsyApiOnReady || []).push(() => {
      window.sndsyApi.ssecEvent(eventName, payload);
    });
    if (typeof window.sndsyApi !== 'undefined') {
      window.sndsyApi.runQueue();
    }
  } catch (e) {
    console.error(e);
  }
}

function sendsayViewItem(data) {
  const params = [
    {
      id: data.id || '',
      name: data.name || '',
      vendor: data.brand || '',
      price: +`${data.total_price}`.replace(/ /g, '') || 0,
      old_price: +`${data.price}`.replace(/ /g, '') || 0,
      url: data.url || '',
      picture: [],
      available: data.available,
    },
  ];

  pushEvent('VIEW_PRODUCT', params);
}

function sendsayAddItem(data) {
  if (!data?.product?.totalSum || !data?.product?.cart) {
    return;
  }

  const params = [
    {
      transaction_sum: data?.product?.totalSum,
      items: data?.product?.cart?.map(item => ({
        id: item.id || '',
        name: item.name || '',
        vendor: item.vendor || '',
        price: item.price || 0,
        old_price: item.oldPrice || 0,
        qnt: item.quantity || 1,
        available: item.available,
        cp1: item.variant,
        uniq: item.variant ? 'cp1' : 'id',
      })),
    },
  ];

  pushEvent('BASKET_ADD', params);
}

export { Init, sendsayViewItem, sendsayAddItem };
