import Analytics from 'src/features/analytics';
import { ANALYTICS } from 'src/constants/analytics';

import {
  AddToCart,
  BeginCheckout,
  Init as _Init,
  MedicalAppointment,
  MedicalAppointmentSale,
  ProductDetail,
  ProductPage,
  RemoveFromCart,
  // ShippingCheckout,
} from 'src/features/analytics/vk-analytics/vk';

export function Init() {
  _Init();

  Analytics.addListener([ANALYTICS.BEGIN_CHECKOUT], BeginCheckout);
  // Analytics.addListener([ANALYTICS.SHIPPING_CHECKOUT], ShippingCheckout); не используется событие
  Analytics.addListener([ANALYTICS.CATALOG], ProductPage);
  Analytics.addListener([ANALYTICS.ITEM_ADD], AddToCart);
  Analytics.addListener([ANALYTICS.ITEM_REMOVE], RemoveFromCart);
  Analytics.addListener([ANALYTICS.PRODUCT_DETAIL], ProductDetail);

  Analytics.addListener([ANALYTICS.MEDICAL_APPOINTMENT_SALE], MedicalAppointmentSale);

  Analytics.addListener([ANALYTICS.MEDICAL_APPOINTMENT], MedicalAppointment);
}
