import mapData from 'src/utils/mapper';
import { ANALYTICS, ANALYTICS_STEP_CART } from 'src/constants/analytics';

function Init() {
  /** Google Tag Manager **/

  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        '//www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-M8J6HX');

  /** Google Analytics */

  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    let result = '';
    for (let i = 0; i < 8; i++) {
      result += s4();
    }
    return result;
  }

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () { dataLayer.push(arguments) }
  gtag('js', new Date());
  gtag('set', { 'sessionId': guid() });
  gtag('config', 'UA-47092091-3', {
    'custom_map': {
      'dimension20': 'clientId',
      'dimension8': 'sessionId'
    }
  });

  gtag('config', 'AW-808555347');

  /* Ga4 */
  gtag('config', 'G-7M7ZTGE9Z2');

  window.analytics = window.analytics || { products: {}, list: 'catalog' };
}

/* productClick UA */
function GtagProductClick(data) {
  let product = {};
  if (Array.isArray(data)) {
    product = {
      ...data[1],
      position: data[0],
      idForAnalytics: data[1].products.id,
    }
  } else {
    product = {
      ...data,
      idForAnalytics: data.idForAnalytics[0],
    };
  }

  const params = {
    ecommerce: {
      click: {
        products: [{
          name: product.name,
          id: product.id,
          price: product.price,
          brand: product.brand,
          category: product.category,
          variant: product.variant ? product.variant : '',
          position: product.position,
          idForAnalytics: product.idForAnalytics,
        }]
      }
    },
    'eventCallback': function () {
      document.location = product.url
    }
  }
  gtag('event', 'productClick', params);
}

/* addToCart & removeFromCart  UA */
function GtagCartItem(data) {
  if (data && data.product) {
    const obj = {
      ecommerce: {
        currencyCode: 'RUB',
        [data.isAdd ? 'add' : 'remove']: {
          products: [
            {
              name: data.product.name,
              id: data.product.id,
              price: data.product.price,
              brand: data.product.brand,
              category: data.product.category,
              variant: data.product.variant,
              quantity: data.product.quantity,
              idForAnalytics: data.product.idForAnalytics[0],
            }
          ]
        }
      }
    }
    gtag('event', data.isAdd ? 'addToCart' : 'removeFromCart', obj);
  }
}

/* checkout steps UA */
function GtagBeginCheckout(data) {
  if (data.items.length) {
    const obj = {
      ecommerce: {
        checkout: {
          actionField: { step: data.step },
          products: data.items.map(item => ({
            name: item.name,
            id: item.id,
            price: item.price,
            brand: item.brand,
            category: item.category,
            variant: item.variant,
            quantity: item.quantity,
            idForAnalytics: item.idForAnalytics[0],
          }))
        }
      },
      'eventCallback': function () {
        document.location = data.url
      }
    }
    gtag('event', 'checkout', obj);
  }
}

function GtagListingView(data) {
  try {
    setTimeout(function () {
      if (window.UXS !== undefined) {
        window.UXS.sendEvent(ANALYTICS.LISTING_VIEW);
      }
    }, 1000 * 5);

    gtag('event', ANALYTICS.LISTING_VIEW, {});
    ProductShow(data)
  } catch (e) {}
}

function GtagAuth(data) {
  if (data.type === undefined) {
    return;
  }
  try {
    gtag('event', 'event_name', {
      event_category: 'login',
      event_action: 'avthorization',
      event_label: 'phone',
    });
  } catch (e) {}
}

function GtagRegister() {
  try {
    gtag('event', 'event_name', {
      event_category: 'registration',
      event_action: 'registration',
    });
  } catch (e) {}
}

function GtagCheckoutProgress(data) {
  if (ANALYTICS_STEP_CART[data.step]) {
    data.category = ANALYTICS_STEP_CART[data.step].category;
    data.event = ANALYTICS_STEP_CART[data.step].event;
    data.progress = ANALYTICS_STEP_CART[data.step].progress;
    if (data && data.items) {
      try {
        gtag('event', 'event_name', {
          event_category: data.category,
          event_action: data.event,
        });
        gtag('event', data.progress, {
          items: data.items,
        });
      } catch (e) {}
    }
  }
}

function GtagEventSend(data) {
  if (data) {
    try {
      gtag('event', data.event, data.params);
    } catch (e) {}
  }
}

function GtagWithPayload(data) {
  if (typeof (gtag) === 'undefined') {
    return;
  }
  if (data.event === undefined || data.event_name === undefined || data.info === undefined) {
    return;
  }
  gtag(data.event, data.event_name, data.info);
}


/**
 * При создании записи ко врачу
 *
 * @param {Object} record Запись
 * @return {boolean} в случае успеха возвращает false, иначе true
 */
function MedicalAppointment(record) {
  try {
    gtag('event', 'conversion', {
      'send_to': 'AW-808555347/awndCPXHsbUBENOmxoED',
      'event_callback': function () {
      }
    });
  } catch (e) {
    return true;
  }

  return false;
}

function MedicalAppointmentSale() {
  try {
    gtag('event', 'conversion', {
      'send_to': 'AW-808555347/nSoNCNuAubUBENOmxoED',
      'event_callback': function () {
      }
    });
  } catch (e) {}
}

function ProductShow(data) {
  if (data.gtag) {
    const obj = {
      ecommerce: {
      currencyCode: 'RUB',
        impressions:
        data.gtag.map(item => mapData(item, {
            id: 'id',
            name: 'name',
            list: 'list_name',
            brand: 'brand',
            category: 'category',
            price: 'price',
            variant: 'variant',
            position: 'list_position'
          })),
      }
    }
    gtag('event','impressions',obj)
  }
}

function ShowBanners(data) {
  const banners = data.slider;

  const resultBanners = [];

  Object.keys(banners).forEach(item => {
    const { id, name, position } = banners[item];
    resultBanners.push({
      id,
      name,
      position
    })
  })

  const obj = {
    'ecommerce': {
      'promoView': {
        'promotions': resultBanners.map(item => mapData(item, {
          id: 'id',
          name: 'name',
          position: 'position'
        }))
      }
    }
  }

  gtag('event', 'promoView', obj);
}

function ClickBanners(data) {
  const obj = {
    'event': 'promotionClick',
    'ecommerce': {
      'promoClick': {
        'promotions': [data]
      }
    }
  }

  gtag('event', 'promoClick', obj)
}

export {
  GtagCartItem,
  GtagAuth,
  GtagRegister,
  GtagCheckoutProgress,
  GtagEventSend,
  GtagWithPayload,
  MedicalAppointment,
  Init,
  MedicalAppointmentSale,
  GtagListingView,
  ProductShow,
  GtagProductClick,
  ShowBanners,
  ClickBanners,
  GtagBeginCheckout,
};
