/* eslint-disable no-undef,no-unused-vars,no-underscore-dangle,no-use-before-define,no-var,vars-on-top */
import { ANALYTICS } from 'src/constants/analytics';
import Analytics from 'src/features/analytics';
import { Init as _Init } from './mailru';

export function Init() {
  _Init();
  const MAIL_ID = 3328424;

  Analytics.addListener(ANALYTICS.PRODUCT_DETAIL, data => {
    data.idForAnalytics.forEach(id => {
      var _tmr = _tmr || [];
      _tmr.push({
        type: 'reachGoal',
        id: MAIL_ID,
        goal: 'product',
        value: data.total_price,
        params: {
          product_id: id,
        },
      });
    });
  });

  Analytics.addListener(ANALYTICS.ITEM_ADD, data => {
    data.product.idForAnalytics.forEach(id => {
      var _tmr = _tmr || [];
      _tmr.push({
        type: 'reachGoal',
        id: MAIL_ID,
        goal: 'basket',
        value: data.total_price,
        params: {
          product_id: id,
        },
      });
    });
  });

  Analytics.addListener(ANALYTICS.SUCCESS_CHECKOUT, data => {
    const value = data.my_target;
    var _tmr = _tmr || [];
    _tmr.push({
      type: 'reachGoal',
      id: MAIL_ID,
      goal: 'buy',
      value: value.cost,
      params: {
        product_id: value.products.map(product => product.idForAnalytics[0]),
      },
    });
  });
}
