import { Init as AdmitadInit } from 'src/features/analytics/admitad-analytics';
import { Init as AdvcakeInit } from 'src/features/analytics/advcake-analytics';
// import { Init as InitAndata } from 'src/features/analytics/andata';
// import { Init as CriteoInit } from 'src/features/analytics/criteo-analytics';
// import { Init as FacebookInit } from 'src/features/analytics/facebook-analytics';
import { Init as MailruInit } from 'src/features/analytics/mailru-analytics';
import { Init as VkInit } from 'src/features/analytics/vk-analytics';
import { Init as YandexInit } from 'src/features/analytics/yandex-analytics';
import { Init as GoogleInit } from 'src/features/analytics/google-analytics';
import { Init as SendsayInit } from 'src/features/analytics/sendsay';
// import { Init as SegmentStreamInit } from 'src/features/analytics/segment-stream-analytics';
// import { Init as RTBInit } from 'src/features/analytics/rtb_house';
import { Init as AdspireInit } from 'src/features/analytics/adspire-analytics';

import { InitAllTriggers } from 'src/features/analytics/initTriggers';

function Init() {
  if (!globalThis?.xliteConfig?.isAnalyticsEnabled) {
    // eslint-disable-next-line no-console
    console.info('ENABLE_ANALYTICS is disabled');
    return;
  }

  InitAllTriggers();

  GoogleInit();
  SendsayInit();
  // отключено
  // SegmentStreamInit();
  // InitAndata();
  // CriteoInit();
  // RTBInit();

  VkInit();
  // FacebookInit();
  MailruInit();
  YandexInit();
  AdmitadInit();
  AdvcakeInit();
  AdspireInit();
  window.xcore.trigger('analyticsAllTriggersLoaded');
}

window.addEventListener('load', Init);
