import { ANALYTICS } from 'src/constants/analytics';
import Analytics from 'src/features/analytics';
import { waitFor } from 'src/utils/wait-for';
import { Init as _Init } from './advcake';

export function Init() {
  let baseData = { email: '' };
  let headInitialized = false;
  const PAGE_TYPE_LEAD_WATCH = 2;
  const PAGE_TYPE_LEAD_START = 3;
  const PAGE_TYPE_LEAD_FINISH = 6;

  _Init();

  function stringToNumber(str) {
    return Number(
      String(str)
        .split(' ')
        .join(''),
    );
  }

  /**
   * Создание лида записи ко врачу. Универсальная функция, формирует данные на базе pageType. Поддерживаемые типы
   * страниц
   *  - 2: Страница просмотра лида,
   *  - 3: Начало формирования лида,
   *  - 4: Страница созданного лида,
   *
   * Возвращает Promise
   *
   * - resolve с объектом события,
   * - reject вызывается при неопределенном pageType, record.service.description, record.service.code
   *
   * @see https://jira.itech-group.ru/browse/OCHKARBUS-16282
   * @see https://support.advcake.com/docs/advdocs/integration/pixel-lead.html#data
   *
   * @param {Object} record Запись
   * @param {number} pageType Тип страницы
   * @return {Promise}
   */
  function MedicalAppointmentLead(record, pageType) {
    return new Promise((resolve, reject) => {
      if (typeof pageType === 'undefined') {
        return reject(new Error('Тип страницы лида advkake не определен'));
      }
      const pageInfo = { pageType };
      const userInfo = {
        user: {
          email: record?.emailmd || baseData.email,
        },
      };
      const serviceDescription = record?.service?.description;
      const leadid = record?.service?.code;

      if (serviceDescription === undefined) {
        return reject(new Error('Ошибка, lead name не определен'));
      }
      if (leadid === undefined) {
        return reject(new Error('Ошибка, leadid не определен'));
      }
      let leadAddition = {};
      let leadInfo = {};

      if (pageType === PAGE_TYPE_LEAD_START || pageType === PAGE_TYPE_LEAD_WATCH) {
        leadAddition = {
          currentLead: {
            leadid,
            name: serviceDescription,
          },
        };
      }

      if (pageType === PAGE_TYPE_LEAD_FINISH) {
        leadInfo = {
          leadInfo: {
            id: record?.id,
            leadid,
            name: serviceDescription,
          },
        };
      }
      window.advcake_data = window.advcake_data || [];
      const event = { ...pageInfo, ...userInfo, ...leadAddition, ...leadInfo };
      window.advcake_data.push(event);
      resolve(event);
    });
  }

  /**
   * Инициализирует базовые данные.
   *
   * @param {Object} commonHeadData Общие данные заголовка
   * @see classes/XLite/Module/Itech/UniversalAnalytics/View/Header.php::getHeadAnalytics()
   */
  function initializeBaseData(commonHeadData) {
    baseData = {
      email: commonHeadData?.emailmd || '',
      action: commonHeadData?.action || '',
    };
    headInitialized = true;
  }
  function waitForAction(action) {
    return waitFor(() => headInitialized === true).then(
      resolved =>
        new Promise((resolve, reject) => {
          if (baseData.action !== action) {
            return reject(new Error('wrong action'));
          }
          resolve(resolved);
        }),
    );
  }

  Analytics.addListener(ANALYTICS.HEAD, initializeBaseData);

  Analytics.addListener(ANALYTICS.MEDICAL_APPOINTMENT, record => {
    MedicalAppointmentLead(record, PAGE_TYPE_LEAD_FINISH)
      // .then(console.info)
      .catch(console.warn);
  });

  Analytics.addListener(ANALYTICS.MEDICAL_APPOINTMENT_WATCH, record => {
    waitForAction('service')
      .then(() => MedicalAppointmentLead(record, PAGE_TYPE_LEAD_WATCH))
      // .then(console.info)
      .catch(console.warn);
  });

  Analytics.addListener(ANALYTICS.MEDICAL_APPOINTMENT_START, record => {
    waitForAction('service')
      .then(() => MedicalAppointmentLead(record, PAGE_TYPE_LEAD_START))
      // .then(console.info)
      .catch(console.warn);
  });

  // eslint-disable-next-line no-unused-vars
  Analytics.addListener(ANALYTICS.MAIN_PAGE, data => {
    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 1,
      user: {
        email: baseData.email,
      },
    });
  });

  Analytics.addListener(ANALYTICS.PRODUCT_DETAIL, data => {
    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 2,
      user: {
        email: data.emailHash,
      },
      currentCategory: {
        id: data.categoryId,
        name: data.category,
      },
      currentProduct: {
        id: data.sku,
        name: data.name,
        price: data.salePrice,
      },
      basketProducts: [],
    });
  });

  Analytics.addListener(ANALYTICS.CATALOG, data => {
    if (data.advcake === undefined) {
      return;
    }
    const adv = data.advcake;
    if (adv.isSearch === false && adv.isCatalog === false) {
      return;
    }
    let pageType = 3;
    if (adv.isSearch === true) {
      pageType = 7;
    }

    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType,
      user: {
        email: adv.emailHash,
      },
      currentCategory: {
        id: adv.pageCategoryId,
        name: adv.pageCategoryLabel,
      },
      products: adv.products,
      basketProducts: [],
    });
  });

  Analytics.addListener(ANALYTICS.CART, data => {
    if (data.items === undefined) {
      return;
    }
    const items = data.items.map(item => ({
      id: item.sku,
      name: item.name,
      price: item.salePrice,
      quantity: item.quantity,
      categoryId: item.categoryId,
      categoryName: item.categoryLabel,
    }));
    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 4,
      user: {
        email: data.emailHash,
      },
      basketProducts: items,
    });
  });

  Analytics.addListener(ANALYTICS.PROGRESS, data => {
    if (data.items === undefined) {
      return;
    }
    const items = data.items.map(item => ({
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      categoryId: item.category,
      categoryName: '',
    }));

    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 5,
      user: {
        email: baseData.email,
      }, // email в виде хеша или уникальный идентификатор
      basketProducts: items,
    });
  });

  Analytics.addListener(ANALYTICS.BUY_ONE_CLICK, data => {
    if (data.advcake === undefined || data.advcake.products === undefined) {
      return;
    }

    const { email, products, orderNumber } = data.advcake;
    window.advcake_data = window.advcake_data || [];

    window.advcake_data.push({
      pageType: 6,
      basketProducts: products.map(item => {
        return {
          id: item.productId,
          name: item.name,
          price: stringToNumber(item.price),
          quantity: item.amount,
          categoryId: item.categoryId,
          categoryName: item.categoryName,
        };
      }),
      orderInfo: {
        id: Number(orderNumber),
        totalPrice: products.reduce((acc, curr) => acc + stringToNumber(curr.price), 0),
        coupon: '',
      },
      user: {
        type: '',
        email,
      },
    });
  });
  Analytics.addListener(ANALYTICS.SUCCESS_CHECKOUT, data => {
    if (data.advcake === undefined || data.advcake.products === undefined) {
      return;
    }
    const adv = data.advcake;

    const items = adv.products.map(item => ({
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      categoryId: item.category,
      categoryName: '',
    }));

    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 6,
      user: {
        email: adv.emailHash,
        type: '',
      },
      basketProducts: items,
      orderInfo: {
        id: data.orderNumber,
        totalPrice: data.subTotalPrice,
        coupon: '',
      },
    });
  });
}
