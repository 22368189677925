/* eslint-disable no-undef,no-unused-vars,no-underscore-dangle,no-use-before-define,no-var,vars-on-top */
import { ANALYTICS } from 'src/constants/analytics';
import Analytics from 'src/features/analytics';
import { GoalReached, Init as _Init } from './yandex';

const GOALS = {
  EXPERT_ENTRY: 'goal-experts-entry',
  BANNER_CRIZAL: 'goal-banner-crizal',
  SALONY_OPTIKI: 'goal-salony-optiki-entry',
  REVIEW_SEND: 'goal-review-send',
  FORGOTTEN_CART: 'goal-forgotten-cart',
  COMPARE: 'goal-compare',
  TRY_ON_COLOR_LENSE: 'goal-try-on-colour-lens',
  ZAKAZ_OFORMLEN: 'goal-zakaz_oformlen',
  DOCTOR_ENTRY_SEND: 'goal-doctor-send',
  DOCTOR_SALON_SEND: 'goal-doctor-salony-send',
  DOCTOR_ESSILOR_SEND: 'goal-doctor-essilor-send',
  WITH_EVENT_NAME: 'goal-evented',
};

export function Init() {
  _Init();

  // window.xcore.trigger('goal-evented', {});

  Analytics.addListener(ANALYTICS.SUCCESS_CHECKOUT, () => {
    GoalReached('checkout-review');
  });

  Analytics.addListener(ANALYTICS.BILLING_CHECKOUT, () => {
    GoalReached('checkout-addressee');
  });

  Analytics.addListener(ANALYTICS.APP_STORE, () => {
    GoalReached('appstore_app_ochkarik');
  });

  Analytics.addListener(ANALYTICS.GOOGLE_PLAY, () => {
    GoalReached('google_play_app_ochkarik');
  });

  Analytics.addListener(ANALYTICS.REGISTER, () => {
    GoalReached('registration');
  });

  Analytics.addListener(ANALYTICS.AUTH, () => {
    GoalReached('authorization');
  });

  Analytics.addListener(ANALYTICS.CALLBACK_FORM, () => {
    GoalReached('callback-form');
  });

  Analytics.addListener(ANALYTICS.CONTACT_US_FORM, () => {
    GoalReached('contacts-form');
  });

  Analytics.addListener(ANALYTICS.ITEM_ADD, () => {
    GoalReached('adtocart');
  });

  Analytics.addListener(GOALS.EXPERT_ENTRY, () => {
    GoalReached('experts-entry');
  });

  Analytics.addListener(GOALS.BANNER_CRIZAL, () => {
    GoalReached('banner_crizal');
  });

  Analytics.addListener(GOALS.SALONY_OPTIKI, () => {
    GoalReached('salony-optiki-entry');
  });

  Analytics.addListener(ANALYTICS.NO_LK_CHECKOUT, () => {
    GoalReached('checkout_without_registration');
  });

  Analytics.addListener(ANALYTICS.REVIEW_SEND, () => {
    GoalReached('review_send');
  });

  Analytics.addListener(GOALS.FORGOTTEN_CART, () => {
    GoalReached('forgotten-cart');
  });

  Analytics.addListener(ANALYTICS.COMPARE, () => {
    GoalReached('compare');
  });

  Analytics.addListener(GOALS.TRY_ON_COLOR_LENSE, () => {
    GoalReached('try-on-colour-lens');
  });

  Analytics.addListener(GOALS.ZAKAZ_OFORMLEN, data => {
    GoalReached('Zakaz_Oformlen', data);
  });

  Analytics.addListener(GOALS.DOCTOR_SALON_SEND, () => {
    GoalReached('doctor-send_salony-optiki_send');
  });

  Analytics.addListener(GOALS.DOCTOR_ESSILOR_SEND, () => {
    GoalReached('doctor-send-essilor-send');
  });

  Analytics.addListener(ANALYTICS.FORM_SALON, () => {
    GoalReached('form-salon');
  });

  Analytics.addListener(ANALYTICS.FORM_SPECIALIST, () => {
    GoalReached('form-specialist');
  });

  Analytics.addListener(ANALYTICS.FORM_DATE, () => {
    GoalReached('form-date');
  });

  Analytics.addListener(ANALYTICS.DOCTOR_ENTRY_SEND, () => {
    GoalReached('doctor-entry-send');
  });

  Analytics.addListener(GOALS.WITH_EVENT_NAME, data => {
    if (data.event_name === undefined) {
      return;
    }
    GoalReached(data.event_name);
  });
}
