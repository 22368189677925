/* eslint-disable no-plusplus */
import {
  AdmitAdCart,
  AdmitAdCatalog,
  AdmitAdCheckoutSuccess,
  AdmitAdMainPage,
  AdmitAdProduct,
} from 'src/features/analytics/admitad-analytics/admitad';
import { ANALYTICS } from 'src/constants';
import Analytics from 'src/features/analytics';

export function Init() {
  Analytics.addListener(ANALYTICS.CART, data => {
    const payload = [];
    for (let idx = 0; idx < data.items.length; idx++) {
      const item = data.items[idx];
      payload.push({
        id: item.id,
        number: item.quantity,
      });
    }
    AdmitAdCart(payload);
  });

  Analytics.addListener(ANALYTICS.CATALOG, data => {
    if (data.admitad === undefined || data.admitad.categoryId === undefined) {
      return;
    }
    AdmitAdCatalog(data.admitad.categoryId);
  });

  Analytics.addListener(ANALYTICS.SUCCESS_CHECKOUT, data => {
    if (data.orderId === undefined || data.admitad === undefined) {
      return;
    }
    const payload = {
      orderId: data.orderId,
      amount: data.admitad.orderAmount,
      items: data.admitad.items,
    };

    AdmitAdCheckoutSuccess(payload);
  });

  Analytics.addListener(ANALYTICS.SUCCESS_CHECKOUT, () => {
    AdmitAdMainPage();
  });

  Analytics.addListener(ANALYTICS.PRODUCT_DETAIL, data => {
    if (data.id === undefined) {
      return;
    }

    const payload = {
      id: data.id,
      vendor: data.manufacturerName,
      price: data.salePrice,
      url: data.url,
      picture: data.image,
      name: data.name,
      category: data.categoryId,
    };
    AdmitAdProduct(payload);
  });
}
