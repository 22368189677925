import Analytics from 'src/features/analytics';
import { ANALYTICS } from 'src/constants/analytics';
import { Init as _Init, sendsayViewItem, sendsayAddItem } from './sendsay';

export function Init() {
  _Init();

  /* VIEW_PRODUCT sendsay */
  Analytics.addListener([ANALYTICS.PRODUCT_DETAIL], sendsayViewItem);

  /* BASKET_ADD sendsay */
  Analytics.addListener([ANALYTICS.ITEM_ADD], sendsayAddItem);
}
