/* eslint-disable prefer-template,no-underscore-dangle */
export function Init(code) {
  window._retag = window._retag || [];
  window._retag.push(code);
  const id = 'admitad-retag';
  if (document.getElementById(id)) {
    return;
  }
  const s = document.createElement('script');
  s.async = true;
  s.defer = false;
  s.id = id;
  const r = new Date().getDate();
  s.src = (document.location.protocol === 'https:' ? 'https:' : 'http:') + '//cdn.lenmit.com/static/js/retag.js?r=' + r;
  const a = document.getElementsByTagName('script')[0];
  a.parentNode.insertBefore(s, a);
}

export function AdmitAdCart(payload) {
  window.ad_products = payload;
  const code = {
    code: '9ce8884277',
    level: 3,
  };
  Init(code);
}

export function AdmitAdCatalog(payload) {
  window.ad_category = payload;
  const code = {
    code: '9ce8884275',
    level: 1,
  };
  Init(code);
}

export function AdmitAdCheckoutSuccess(payload) {
  window.ad_order = payload.orderId;
  window.ad_amount = payload.amount;
  window.ad_products = payload.items;
  const code = {
    code: '9ce8884276',
    level: 4,
  };
  Init(code);
}

export function AdmitAdMainPage() {
  const code = {
    code: '9ce888426a',
    level: 0,
  };
  Init(code);
}

export function AdmitAdProduct(payload) {
  window.ad_product = payload;

  const code = {
    code: '9ce8884274',
    level: 2,
  };
  Init(code);
}
